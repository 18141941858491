import { MRT_RowSelectionState } from 'mantine-react-table';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../hooks/utils/useNotifications';
import { AssetPdf } from '../../../pages/Asset/AssetPdf/AssetPdf';
import { InterfaceFullAsset } from '../../../types/FullInterfaces';
import { ConfirmAssociationModal } from '../../Assets/ConfirmAssociationModal';
import { MarkInactiveAssetWarningModal } from '../../Assets/MarkInactiveAssetWarningModal';
import { AddAssetsToProduct } from '../../Product/AddAssetsToProduct/AddAssetsToProduct';
import { BulkRemoveAssetsFromProduct } from '../../Product/BulkRemoveAssetsFromProduct/BulkRemoveAssetsFromProduct';
import ProductsAssociationModal from '../../Product/ProductsAssociationModal/ProductsAssociationModal';
import { RemoveAssetFromProductModal } from '../../Product/RemoveAssetFromProductModal/RemoveAssetFromProductModal';
import {
  AssetModals,
  AssetModalsControls,
  AssetModalsState,
} from './state/useAssetModals';

interface Props {
  modalState: AssetModalsState;
  modalControls: AssetModalsControls;
  state: {
    productId?: string;
    selectedAsset?: InterfaceFullAsset;
    fetchedAssets: InterfaceFullAsset[];
    fetchedModalAssets: InterfaceFullAsset[];
    rowSelection: MRT_RowSelectionState;
    selectedRowsAmount: number;
    selectedProductsAmount: number;
    viewExportDocument: boolean;
    assetAssociation: Array<string | undefined>;
  };
  actions: {
    setRowSelection: Dispatch<SetStateAction<MRT_RowSelectionState>>;
    setSelectedProductsAmount: Dispatch<SetStateAction<number>>;
    setViewExportDocument: Dispatch<SetStateAction<boolean>>;
    handleMarkInactiveAssetWarning: (selectedAssetIds: Array<string | undefined>) => void;
  };
}

export const AssetsTableModals = ({
  modalState,
  modalControls,
  state,
  actions,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { success: showSuccessNotification } = useNotifications();

  return (
    <>
      <AddAssetsToProduct
        assets={state.fetchedModalAssets}
        currentAssets={state.fetchedAssets}
        isOpen={modalState[AssetModals.ProductAssets]}
        close={() => modalControls.closeModal(AssetModals.ProductAssets)}
        productId={state.productId}
      />
      <RemoveAssetFromProductModal
        isOpen={modalState[AssetModals.RemoveAsset]}
        close={() => modalControls.closeModal(AssetModals.RemoveAsset)}
        asset={state.selectedAsset}
        productId={state.productId}
      />
      <BulkRemoveAssetsFromProduct
        productId={state.productId}
        assetsIds={state.rowSelection}
        close={() => modalControls.closeModal(AssetModals.BulkRemoveAssetsFromProduct)}
        isOpen={modalState[AssetModals.BulkRemoveAssetsFromProduct]}
      />
      <ProductsAssociationModal
        opened={modalState[AssetModals.ProductsAssociation]}
        close={() => modalControls.closeModal(AssetModals.ProductsAssociation)}
        assetsIds={state.rowSelection}
        clearRowSelection={() => actions.setRowSelection({})}
        openNotificationModal={() =>
          showSuccessNotification({
            title: t(
              `tables.products.assets-added-to-products.${
                state.selectedRowsAmount === 1 ? 'one' : 'many'
              }-${state.selectedProductsAmount === 1 ? 'one' : 'many'}`,
              {
                assetsAmount: state.selectedRowsAmount,
                productsAmount: state.selectedProductsAmount,
              },
            ),

            message: t('tables.products.assetsAddedToProductsDescription'),
            action: {
              label: t('tables.products.see-all-products'),
              onClick: () => navigate('/products'),
            },
            autoClose: 30 * 1000,
          })
        }
        updateSelectedProductsCallback={actions.setSelectedProductsAmount}
      />
      <ConfirmAssociationModal
        confirm={() => {
          modalControls.closeModal(AssetModals.ConfirmAssociation);
          modalControls.openModal(AssetModals.ProductsAssociation);
        }}
        isOpen={modalState[AssetModals.ConfirmAssociation]}
        close={() => modalControls.closeModal(AssetModals.ConfirmAssociation)}
      />
      <MarkInactiveAssetWarningModal
        isOpen={modalState[AssetModals.MarkInactiveAssetWarning]}
        close={() => modalControls.closeModal(AssetModals.MarkInactiveAssetWarning)}
        confirm={() => {
          actions.handleMarkInactiveAssetWarning(state.assetAssociation);
        }}
        count={state.assetAssociation.length}
      />
      {!!state.selectedAsset && (
        <AssetPdf
          viewExportDocument={state.viewExportDocument}
          setViewExportDocument={actions.setViewExportDocument}
          assetId={`${state.selectedAsset._id}`}
          organizationId={`${state.selectedAsset?.organizationId}`}
        />
      )}
    </>
  );
};

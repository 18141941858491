import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { Box, Flex, Radio, Stack } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postGenerateSBOM } from '../../../api/product.api';
import { Logger } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import ManifestModal from '../../ManifestModal/ManifestModal';

export type DownloadProductSbomModalsteps = 'selectFormat' | 'emailOnTheWay';

interface DownloadSBOMModalProps {
  onClose: () => void;
  product: InterfaceOrganizationProduct;
  initialStep?: DownloadProductSbomModalsteps;
}

export const DownloadSBOMModal = ({
  onClose,
  product,
  initialStep = 'selectFormat',
}: DownloadSBOMModalProps) => {
  const { t } = useTranslation();
  const [selectedFormat, setSelectedFormat] = useState<string | undefined>(undefined);
  const [step, setStep] = useState<DownloadProductSbomModalsteps>(initialStep);

  const downloadSbomHandler = async (format?: string) => {
    if (!product._id || !format) {
      return;
    }

    try {
      setStep('emailOnTheWay');
      await postGenerateSBOM(
        product._id.toString(),
        product.name || '',
        product.version || '',
        format,
      );
    } catch (error) {
      Logger.error('Error generating product sbom', error);
    }
  };

  const title =
    step === 'selectFormat'
      ? t('product.download-modal.title')
      : t('product.sbomEmailOnTheWay');

  const subtitle =
    step === 'selectFormat' ? `${t('product.download-modal.description')}` : '';

  const secondaryAction =
    step === 'selectFormat'
      ? {
          label: t('product.download-modal.cancel'),
          onClick: onClose,
        }
      : undefined;

  const primaryActionLabel =
    step === 'selectFormat' ? t('product.download-modal.download') : t('Ok');
  return (
    <ManifestModal
      size="md"
      opened
      withCloseButton={true}
      onClose={() => {
        onClose();
      }}
      title={title}
      subtitle={subtitle}
      body={
        <>
          {step === 'selectFormat' && (
            <Flex w="100%" direction="column" gap="12px">
              <Radio.Group
                required
                size="md"
                withAsterisk
                value={selectedFormat}
                onChange={setSelectedFormat}
                label={t('product.download-modal.call-to-action')}
              >
                <Stack mt={'xs'} spacing="xs">
                  <Radio
                    key={'CycloneDX'}
                    value={'CycloneDX'}
                    label={t('product.download-modal.cyclonedx')}
                  />
                  <Radio
                    key={'SPDX'}
                    value={'SPDX'}
                    label={t('product.download-modal.spdx')}
                  />
                </Stack>
              </Radio.Group>
            </Flex>
          )}

          {step === 'emailOnTheWay' && (
            <Box>
              <Box mb="16px">{t('product.sbomEmailOnTheWayDescription')}</Box>
              {t('product.sbomEmailOnTheWay24HoursDisclaimer')}{' '}
            </Box>
          )}
        </>
      }
      primaryAction={{
        label: primaryActionLabel,
        onClick: () => {
          if (step === 'selectFormat') {
            downloadSbomHandler(selectedFormat);
            return;
          }

          onClose();
        },
        isDisabled: step === 'selectFormat' && !selectedFormat,
      }}
      secondaryAction={secondaryAction}
    />
  );
};

import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { Tooltip } from '@mantine/core';
import { DateTime } from 'luxon';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../components/ClickableRegion';
import { ManifestTag } from '../../components/ManifestTag/ManifestTag';
import { useFetchVulns } from '../../hooks/queries/useFetchVulns';
import { CvssScore, CvssScoreProps, extractScoreSeverity } from '../CvssScore/CvssScore';
import { getDefaultTableOptions } from '../MRT/ManifestMRT';
import DataTableFooter from './DataTableFooter';

const defaultTableOptions = getDefaultTableOptions<InterfaceVulnerability>();

interface Props {
  assetId?: string;
  organizationId?: string;
}

export const VulnerabilitiesTable = ({ assetId, organizationId }: Props) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: 'preferredScore',
      desc: true,
    },
  ]);

  const {
    data: {
      data: fetchedVulnerabilities = [],
      queryInfo: { countVulnerabilities = 0 } = {},
    } = {},
    isError: isErrorLoadingVulnerabilities,
    isLoading: isLoadingVulnerabilities,
    isFetching: isFetchingVulnerabilities,
  } = useFetchVulns({
    assetId,
    organizationId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
  });

  const columns = useMemo<MRT_ColumnDef<InterfaceVulnerability>[]>(
    () => [
      {
        accessorFn: (row) => row.cveId,
        id: 'cveId',
        header: t('page.vulnerabilities.table.header.cveId'),
        Cell: ({ cell }) => {
          if (cell.getValue()) {
            return (
              <ClickableRegion
                className="table-link"
                regionLabel={t(
                  'page.vulnerabilities.table.body.viewVulnerabilityDetails',
                )}
                href={`/vulnerability/${`${cell.getValue()}`.toUpperCase()}`}
              >
                <span>{`${cell.getValue()}`.toUpperCase()}</span>
              </ClickableRegion>
            );
          }

          return `${cell.getValue()}`.toUpperCase();
        },
      },
      {
        accessorFn: (row) => row.preferredScore,
        id: 'preferredScore',
        header: t('page.vulnerabilities.table.header.severity-impact'),
        Cell: ({ row }) => {
          const { severity, score } = extractScoreSeverity(row.original);
          return (
            <span className={`table-severity`}>
              <CvssScore
                score={score}
                severity={severity as CvssScoreProps['severity']}
                emptyText={t('global.notProvided')}
              />
            </span>
          );
        },
      },
      {
        accessorFn: (row) => row.epssScore,
        id: 'epssScore',
        size: 200,
        header: t('page.vulnerabilities.table.header.epssScore'),
        Cell: ({ row }) => {
          const { epssScore } = row.original;
          let displayValue = null;

          if (epssScore && epssScore < 1) {
            displayValue = `${Math.round(epssScore * 10000) / 100}%`;
          } else if (epssScore) {
            displayValue = `${epssScore}%`;
          }

          if (displayValue && displayValue.length > 1) {
            return (
              <Tooltip
                label={t('page.vulnerabilities.table.body.rawValueFromEPSS', {
                  value: `${epssScore}`,
                })}
              >
                <span>{displayValue}</span>
              </Tooltip>
            );
          }

          return <span className="table-na">{t('global.notProvided')}</span>;
        },
      },
      {
        accessorFn: (row) => row.epssPercentile,
        id: 'epssPercentile',
        header: t('page.vulnerabilities.table.header.epssPercentile'),
        Cell: ({ row }) => {
          const { epssPercentile } = row.original;
          let displayValue = null;

          if (epssPercentile && epssPercentile < 1) {
            displayValue = `${Math.round(epssPercentile * 10000) / 100}%`;
          } else if (epssPercentile) {
            displayValue = `${epssPercentile}%`;
          }

          if (displayValue && displayValue.length > 1) {
            return (
              <Tooltip
                label={t('page.vulnerabilities.table.body.rawValueFromEPSS', {
                  value: `${epssPercentile}`,
                })}
              >
                <span>{displayValue}</span>
              </Tooltip>
            );
          }

          return <span className="table-na">{t('global.notProvided')}</span>;
        },
      },
      {
        accessorFn: (row) => row.kevData?.inKEV,
        id: 'kevData.inKEV',
        header: t('page.vulnerabilities.table.header.onCISAKEVList'),
        size: 120,
        Cell: ({ row }) => {
          const { kevData } = row.original;

          const kevDateAddedFormatted = DateTime.fromISO(
            kevData?.kevDateAdded || '',
          ).toLocaleString(DateTime.DATE_MED);

          const kevStatus = kevData?.inKEV
            ? 'kev'
            : kevData?.inKEVPreviously
              ? 'prev-kev'
              : null;

          if (!kevStatus) return <strong className="no-kev">{t('global.no')}</strong>;

          return (
            <Tooltip
              label={
                <div className="kev-tooltip">
                  <strong className="kev-tooltip-title">
                    {kevData?.kevVulnerabilityName}
                  </strong>
                  <p>
                    {t('page.vulnerabilities.table.body.reported', {
                      date: kevDateAddedFormatted,
                    })}
                  </p>
                </div>
              }
              width={300}
            >
              <span className="kev-badge-wrapper">
                <ManifestTag variant={kevStatus} />
              </span>
            </Tooltip>
          );
        },
      },
      {
        accessorFn: (row) => row.publishDate,
        id: 'publishDate',
        header: t('page.vulnerabilities.table.header.publishDate'),
        Cell: ({ cell }) => {
          const curr = DateTime.fromISO(cell.getValue<any>()).toLocaleString(
            DateTime.DATETIME_MED,
          );
          return `${curr}`;
        },
      },
      {
        accessorFn: (row) => row.dateModified,
        id: 'dateModified',
        header: t('page.vulnerabilities.table.header.lastUpdated'),
        Cell: ({ cell }) => {
          const curr = DateTime.fromISO(cell.getValue<any>()).toLocaleString(
            DateTime.DATETIME_MED,
          );
          return `${curr}`;
        },
      },
      /*{
        accessorFn: (row) => row.firstSeen,
        id: 'firstSeen',
        header: t('page.vulnerabilities.table.header.firstSeen'),
        minSize: 200,
        Header: () => {
          return (
            <span>
              {t('page.vulnerabilities.table.header.firstSeen')}
              <Tooltip
                classNames={{
                  tooltip: styles.tooltip,
                }}
                label={t('page.vulnerabilities.table.header.firstSeenInfo')}
              >
                <span className={styles.firstSeenContent}>
                  <Icon icon="question-circle" className={styles.firstSeenIcon} />
                </span>
              </Tooltip>
            </span>
          );
        },
        Cell: ({ cell }) => {
          const firstSeenDate = cell.getValue<string>();

          if (!firstSeenDate) {
            return '--';
          }

          const parsedFirstSeenDate = DateTime.fromISO(firstSeenDate).toLocaleString(
            DateTime.DATETIME_MED,
          );
          return `${parsedFirstSeenDate}`;
        },
      },*/
    ],
    [isFetchingVulnerabilities],
  );

  //prefetch the next page for faster navigation
  const {} = useFetchVulns({
    assetId,
    organizationId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 2,
    limit: pagination.pageSize,
  });

  const table = useMantineReactTable<InterfaceVulnerability>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    columns,
    data: fetchedVulnerabilities,
    enableFilters: false,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: countVulnerabilities,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: false,
    },
    state: {
      pagination,
      sorting,
      isLoading: isLoadingVulnerabilities,
      showLoadingOverlay: isFetchingVulnerabilities,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTopToolbarProps: {}, //temp
  });

  return (
    <>
      {isErrorLoadingVulnerabilities && (
        <ul className="page-errors anim-slideInUpShort">
          <li>{t('tables.vulnerabilities.unable-to-fetch-vulnerabilities')}</li>
        </ul>
      )}
      <div className="list-vulnerabilities">
        <MantineReactTable table={table} />
        <DataTableFooter
          currentPage={pagination.pageIndex}
          limitPerPage={pagination.pageSize}
          totalResults={countVulnerabilities}
          onChangePage={setPagination}
        />
      </div>
    </>
  );
};

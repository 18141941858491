import { VulnerabilityRiskAction } from '@manifest-cyber/types/interface/db';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { Badge, Box, Button, Flex, Menu, Title, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { TFunction } from 'i18next';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_Row,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ArrayParam, BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { postGenerateSBOM } from '../../api/product.api';
import BasicSearch from '../../components/BasicSearch';
import { LabelsColumn } from '../../components/DataTables/AssetsTable/columns/Labels.column';
import DataTableFooter from '../../components/DataTables/DataTableFooter';
import Icon from '../../components/Icon';
import ManageLabelsModal from '../../components/Labels/ManageLabelsModal';
import { getDefaultTableOptions } from '../../components/MRT/ManifestMRT';
import { DeleteProductModal } from '../../components/Product/DeleteProductModal/DeleteProductModal';
import DownloadSBOMModal from '../../components/Product/DownloadSBOMModal';
import { DownloadProductSbomModalsteps } from '../../components/Product/DownloadSBOMModal/DownloadSBOMModal';
import { ProductModal } from '../../components/Product/ProductModal/ProductModal';
import { Sidebar } from '../../components/RigthSidebar/Sidebar';
import { useFetchLabels } from '../../hooks/queries/useFetchLabels';
import { useAuth } from '../../hooks/useAuth';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { useURLandLocalStorageSortingState } from '../../hooks/utils/usePersistentStates';
import { Logger } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { Manufacturer } from './components/Manufacturer';
import { Mitigate } from './components/Mitigate';
import { ProductName } from './components/ProductName';
import { ProductsFiltersComponent } from './components/ProductsFilters/ProductsFilters';
import { TotalVulns } from './components/TotalVulns';
import './Products.scss';
import { useFetchProductCapabilities } from './products/useFetchProductCapabilities';
import { PaginationInfo, useFetchProducts } from './products/useFetchProducts';
import { useFetchProductsCVEIDs } from './products/useFetchProductsCVEIDs';
import { ProductFormData } from './products/usePostProduct';
import { usePutProductLabels } from './products/usePutProductLabels';
import { useProductDispatch, useProductState } from './productsContext/products.context';
import {
  ProductFilterCvssSeverity,
  ProductsFilters,
} from './productsContext/products.reducer';

const defaultTableOptions = getDefaultTableOptions<InterfaceOrganizationProduct>();
const getColumns = ({
  t,
  hasWriteAccess,
  onLabelClick,
}: {
  t: TFunction;
  hasWriteAccess: boolean;
  onLabelClick: (labelId: string) => void;
}): MRT_ColumnDef<InterfaceOrganizationProduct>[] => [
  {
    header: t('tables.products.headers.name'),
    accessorKey: 'name',
    Cell: ProductName,
  },
  {
    header: t('tables.products.headers.version'),
    accessorKey: 'version',
  },
  {
    header: t('tables.products.headers.manufacturer'),
    accessorKey: 'manufacturer',
    Cell: Manufacturer,
  },
  {
    header: t('tables.products.headers.assets'),
    accessorKey: 'assetCount',
    size: 100,
  },
  {
    header: t('tables.products.headers.vulns'),
    accessorKey: 'countVulnerabilities.total',
    Cell: TotalVulns,
    size: 150,
  },
  {
    header: t('tables.products.headers.mitigate-vulns'),
    accessorKey: 'countVulnerabilities.mitigate',
    Cell: Mitigate,
    size: 150,
  },
  {
    accessorFn: (row) => row.name,
    id: 'labels',
    header: t('tables.assets.headers.labels'),
    Header: '',
    enableSorting: false,
    Cell: ({ row }: { row: MRT_Row<InterfaceOrganizationProduct> }) => (
      <LabelsColumn<InterfaceOrganizationProduct>
        row={row}
        onLabelClick={onLabelClick}
        hasWriteAccess={hasWriteAccess}
      />
    ),
  },
];

const initialFiltersState: ProductsFilters = {
  onlyHasVulns: false,
  onlyHasLicenseIssues: false,
  hasKevVulns: false,
  cvssScore: undefined,
  cvssSeverity: [],
  isCustomCvssScoreSelected: false,
  productName: '',
  recommendation: [],
  cveIds: [],
  labels: [],
};
export const Products = () => {
  const { isOpenSidebar } = useProductState();
  const dispatchProduct = useProductDispatch();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useURLandLocalStorageSortingState('products', [
    {
      id: 'dateModified',
      desc: true,
    },
  ]);
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductFormData>();
  const [selectedDownloadTarget, setSelectedDownloadTarget] =
    useState<InterfaceOrganizationProduct>();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [labelsModalOpen, setLabelsModalOpen] = useState(false);
  const [selectedProductForLabels, setSelectedProductForLabels] =
    useState<InterfaceOrganizationProduct | null>(null);
  const { mutateAsync: putProductLabels } = usePutProductLabels();
  const { data: labelsOptions = [] } = useFetchLabels({});
  const [downloadSbomModal, setDownloadSbomModal] = useState<
    | {
        initialStep: DownloadProductSbomModalsteps;
      }
    | undefined
  >();

  const form = useForm<ProductsFilters>({
    initialValues: initialFiltersState,
  });
  const [query, setQuery] = useQueryParams({
    labels: ArrayParam,
    cvssScore: ArrayParam,
    cvssSeverity: ArrayParam,
    recommendation: ArrayParam,
    cveIds: ArrayParam,
    hasVulns: BooleanParam,
    hasCriticalVulns: BooleanParam,
    hasKevVulns: BooleanParam,
    productName: StringParam,
    hasLicenseIssues: BooleanParam,
  });
  const hasEnabledFilters = useFeatureFlag('productFilters');
  const {
    isFetching,
    isLoading,
    data: productsResponse,
  } = useFetchProducts({
    sortColumn: sorting[0]?.id,
    sortType: sorting[0]?.desc === true ? -1 : sorting[0]?.desc === false ? 1 : undefined,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filters: form.values,
  });
  const { data: cveIds = [] } = useFetchProductsCVEIDs();

  useEffect(() => {
    setQuery({
      labels: form.values.labels,
      hasKevVulns: form.values.hasKevVulns || undefined,
      hasVulns: form.values.onlyHasVulns || undefined,
      cvssSeverity: form.values.cvssSeverity || undefined,
      ...(form.values.productName && { productName: form.values.productName }),
      ...(form.values.recommendation?.length && {
        recommendation: form.values.recommendation,
      }),
      ...(form.values.cveIds?.length && {
        cveIds: form.values.cveIds,
      }),
      cvssScore: form.values.cvssScore?.map((value) => value.toString()),
    });
  }, [form.values, setQuery]);

  useEffect(() => {
    const lapelsFromParams = (query.labels?.filter(Boolean) || []) as string[];
    const filtersFromQuery: ProductsFilters = {
      labels: lapelsFromParams,
      hasKevVulns: Boolean(query.hasKevVulns),
      onlyHasVulns: Boolean(query.hasVulns),
      onlyHasLicenseIssues: Boolean(query.hasLicenseIssues),
      productName: query.productName || '',
      recommendation: query.recommendation as VulnerabilityRiskAction[],
      cveIds: query.cveIds as string[],
      cvssScore:
        query.cvssScore?.length === 2
          ? (query.cvssScore?.map((value) => Number(value)) as [number, number])
          : undefined,
      cvssSeverity: query.cvssSeverity as ProductFilterCvssSeverity[],
      isCustomCvssScoreSelected: Boolean(query.cvssScore && query.cvssScore.length === 2),
    };

    form.setValues(filtersFromQuery);
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const columns = useMemo<MRT_ColumnDef<InterfaceOrganizationProduct>[]>(
    () =>
      getColumns({
        t,
        hasWriteAccess,
        onLabelClick: (labelId) =>
          form.setFieldValue('labels', [...form.values.labels, labelId]),
      }),
    [],
  );

  const table = useMantineReactTable<InterfaceOrganizationProduct>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    columns,
    data: productsResponse?.data ?? [],
    enableFilters: false,
    enablePagination: false,
    enableRowActions: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: !!productsResponse?.queryInfo?.totalCount
      ? productsResponse.queryInfo.totalCount
      : 0,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: true,
    },
    state: {
      globalFilter: form.values.productName,
      pagination,
      sorting,
      isLoading: isLoading,
      showLoadingOverlay: isFetching,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderRowActionMenuItems: ({ row }) => {
      const setProduct = () => {
        setSelectedProduct({
          id: row.original._id?.toString() || '',
          name: row.original.name || '',
          description: row.original.description || '',
          manufacturer: row.original.manufacturer || '',
          version: row.original.version || '',
          labels: row.original.labelIds?.map((labelId) => labelId.toString()) as string[],
        });
      };

      const downloadProduct = async () => {
        if (!row.original._id) {
          return;
        }

        let format = '';
        setSelectedDownloadTarget(row.original);
        for (const key in capabilitiesData?.capabilities.mergeOutputs) {
          if (!capabilitiesData?.capabilities.mergeOutputs[key]?.lossy) {
            format = key;
            break;
          }
        }

        if (!format) {
          format = Object.keys(capabilitiesData?.capabilities.mergeOutputs!)[0]!;
        }
        try {
          setDownloadSbomModal({
            initialStep: 'emailOnTheWay',
          });
          await postGenerateSBOM(
            row.original._id.toString(),
            row.original.name || '',
            row.original.version || '',
            format,
          );
        } catch (error) {
          Logger.error('Error generating product sbom', error);
        }
      };

      const downloadAsProduct = () => {
        setSelectedDownloadTarget(row.original);
        setDownloadSbomModal({
          initialStep: 'selectFormat',
        });
      };

      const editProduct = () => {
        setProduct();
        setIsEditMode(true);
        handleOpenModal();
      };

      const deleteProduct = () => {
        setProduct();
        handleOpenDeleteModal();
      };

      const { isLoading: isLoadingProductCapabilities, data: capabilitiesData } =
        useFetchProductCapabilities({
          productId: row.original._id?.toString(),
        });

      const mergeOutputCount = Object.keys(
        capabilitiesData?.capabilities.mergeOutputs || {},
      ).length;

      // disabled until we want to start rolling out
      const shouldShowDownloadAs = false && mergeOutputCount > 1;

      const handleEditLabels = () => {
        setSelectedProductForLabels(row.original);
        setLabelsModalOpen(true);
      };

      const unableToMergeReason = useMemo(() => {
        if (capabilitiesData?.capabilities.reason === 'asset formats mismatching')
          return t('product.unable-to-generate-sbom-formats-mismatching');
        if (capabilitiesData?.capabilities.reason === 'assets limit reached')
          return t('product.unable-to-generate-sbom-limit-reached');
        if (capabilitiesData?.capabilities.reason === 'not enough assets')
          return t('product.unable-to-generate-sbom-not-enough-assets');
        return '';
      }, [productsResponse?.data, capabilitiesData?.capabilities.reason]);

      const numOfFormats = Object.keys(
        capabilitiesData?.capabilities.mergeOutputs ?? {},
      ).length;

      const disabledTooltip =
        capabilitiesData?.capabilities.canMerge || !unableToMergeReason;

      const isDisabledDownloadSbom = Boolean(
        isLoadingProductCapabilities || !capabilitiesData?.capabilities.canMerge,
      );
      return (
        <>
          <Tooltip disabled={disabledTooltip} label={unableToMergeReason}>
            <span>
              <Menu.Item
                onClick={downloadProduct}
                icon={<Icon icon="arrow-down-to-bracket" />}
                disabled={isDisabledDownloadSbom}
              >
                {t('tables.products.actions.downloadSbom')}
              </Menu.Item>
              {shouldShowDownloadAs && (
                <Menu.Item
                  onClick={downloadAsProduct}
                  disabled={!capabilitiesData?.capabilities.canMerge}
                  icon={<Icon icon="arrow-down-to-bracket" />}
                >
                  <span>{t('product.downloadSbomAs')}</span>
                </Menu.Item>
              )}
            </span>
          </Tooltip>
          {hasWriteAccess && (
            <>
              <Menu.Item onClick={editProduct} icon={<Icon icon="pencil" />}>
                {t('tables.products.actions.edit')}
              </Menu.Item>
              <Menu.Item onClick={handleEditLabels} icon={<Icon icon="tag" />}>
                {t('tables.products.actions.editLabels')}
              </Menu.Item>
              <Menu.Item onClick={deleteProduct} icon={<Icon icon="trash-can" />}>
                {t('tables.products.actions.delete')}
              </Menu.Item>
            </>
          )}
        </>
      );
    },
    renderTopToolbar: () => {
      return (
        <Flex justify={'space-between'} m="1rem 0">
          <Flex gap="xs">
            <Box miw="260px">
              <BasicSearch
                {...form.getInputProps(`productName`)}
                initialValue={form.values.productName}
                placeholder={t('tables.products.search')}
              />
            </Box>
            <Button
              size="sm"
              onClick={() =>
                dispatchProduct({
                  type: 'SET_IS_OPEN_SIDEBAR',
                  payload: !isOpenSidebar,
                })
              }
              variant="default"
              leftIcon={<Icon icon="filter" />}
            >
              {t('global.filter')}
            </Button>
          </Flex>
          <Flex gap="xs">
            {hasWriteAccess && (
              <Button onClick={handleOpenModal}>{t('product.create-product')}</Button>
            )}
          </Flex>
        </Flex>
      );
    },
  });

  return (
    <section className="page-products anim-slideInUpShort">
      <Helmet title={t('page.products.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <Title mb="sm" order={1} className="anim-slideInDownShort">
        <span>{t('page.products.page-title')}</span>
        <Tooltip label={t('page.products.beta-tooltip')} width={250} multiline>
          <Badge className="beta-chip">{t('global.beta')}</Badge>
        </Tooltip>
      </Title>
      <Sidebar
        size={450}
        title={t('global.filters')}
        titleIcon={<Icon icon="filter" iconStyle="fal" />}
        onClose={() => {
          dispatchProduct({
            type: 'SET_IS_OPEN_SIDEBAR',
            payload: false,
          });
        }}
        isOpened={isOpenSidebar}
      >
        <ProductsFiltersComponent
          cveIds={cveIds}
          hasEnabledFilters={hasEnabledFilters}
          form={form}
          labelsOptions={labelsOptions}
        />
      </Sidebar>
      <div className="list-products">
        <MantineReactTable table={table} />
        <ProductModal
          opened={isModalOpen}
          setOpened={setModalOpen}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          editProductData={selectedProduct}
        />
        <DeleteProductModal
          opened={isDeleteModalOpen}
          setOpened={setDeleteModalOpen}
          productData={selectedProduct}
        />
        {downloadSbomModal && selectedDownloadTarget && (
          <DownloadSBOMModal
            initialStep={downloadSbomModal.initialStep}
            onClose={() => setDownloadSbomModal(undefined)}
            product={selectedDownloadTarget}
          />
        )}
        {selectedProductForLabels && (
          <ManageLabelsModal
            key={`products-table-product-${selectedProductForLabels?._id}`}
            open={labelsModalOpen}
            onClose={() => setLabelsModalOpen(false)}
            putLabels={async (data) => {
              putProductLabels(data);
            }}
            entityType="product"
            labelIds={{
              [selectedProductForLabels._id?.toString() as string]:
                selectedProductForLabels.labelIds?.map((l) => l.toString()) || [],
            }}
          />
        )}
        <DataTableFooter
          currentPage={pagination.pageIndex}
          limitPerPage={pagination.pageSize}
          totalResults={(productsResponse?.queryInfo as PaginationInfo)?.totalCount || 0}
          onChangePage={setPagination}
        />
      </div>
    </section>
  );
};

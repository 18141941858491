import appConfig from './appConfig';

const APP_VERSION = appConfig.version;
const LOCAL_STORAGE_VERSION_KEY = 'app_version';
const TARGET_STORAGE_KEY = 'vulnerabilityTableSettings';
const RESET_LOCAL_STORAGE_ENABLED = false;

export const resetLocalStorageOnUpdate = () => {
  if (!RESET_LOCAL_STORAGE_ENABLED) {
    console.log('Local storage reset is disabled.');
    return;
  }

  const storedVersion = localStorage.getItem(LOCAL_STORAGE_VERSION_KEY);

  if (!storedVersion || storedVersion !== APP_VERSION) {
    console.log(
      'App version changed or first-time load, resetting target storage item...',
    );
    localStorage.removeItem(TARGET_STORAGE_KEY);
    localStorage.setItem(LOCAL_STORAGE_VERSION_KEY, APP_VERSION);
  }
};
